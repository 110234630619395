import { Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '@src/environments/environment';
import { IsPlatformService } from '@services/client/is-platform/is-platform.service';

interface EventData {
  type: string;
  data: {
    entity: string;
    entityId: string;
    changedData: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class SseService {
  eventSource: EventSource | null = null;
  readonly url = `${environment.host}/api/v1/sse`;
  defaultEventNames = ['entityCreated', 'entityDeleted', 'entityChanged'];
  private reconnectInterval = 5000;

  constructor(
    private zone: NgZone,
    private isPlatformService: IsPlatformService,
  ) {}

  getEventSource(): EventSource {
    if (this.isPlatformService.isBrowser()) {
      return new EventSource(this.url);
    } else {
      return null;
    }
  }

  connectToServiceSentEvents(
    eventNames: string[] = this.defaultEventNames,
  ): Observable<EventData> {
    if (this.eventSource) {
      return;
    }

    this.eventSource = this.getEventSource();

    return new Observable((subscriber: Subscriber<EventData>) => {
      this.setupEventSource(subscriber, eventNames);

      return () => {
        this.close();
      };
    });
  }

  subscribeToServiceSentEvents(
    eventNames: string[] = this.defaultEventNames,
  ): Observable<EventData> {
    if (!this.eventSource) {
      this.eventSource = this.getEventSource();
    }

    return new Observable((subscriber: Subscriber<EventData>) => {
      this.setupEventSource(subscriber, eventNames);

      return () => {
        this.close();
      };
    });
  }

  close(): void {
    if (!this.eventSource) {
      return;
    }

    this.eventSource.close();
    this.eventSource = null;
  }

  private setupEventSource(
    subscriber: Subscriber<EventData>,
    eventNames: string[],
  ): void {
    const onError = (error: any) => {
      this.zone.run(() => subscriber.error(error));
      this.reconnect(subscriber, eventNames);
    };

    if (this.eventSource) {
      this.eventSource.onerror = onError;

      eventNames.forEach((event) => {
        this.eventSource!.addEventListener(event, (messageEvent) => {
          const eventData = JSON.parse(messageEvent.data);
          const eventType = messageEvent.type;
          const data = { type: eventType, data: eventData };
          this.zone.run(() => subscriber.next(data));
        });
      });
    }
  }

  private reconnect(
    subscriber: Subscriber<EventData>,
    eventNames: string[],
  ): void {
    setTimeout(() => {
      if (!subscriber.closed) {
        this.connectToServiceSentEvents(eventNames).subscribe({
          next: (data) => subscriber.next(data),
          error: (error) => {
            subscriber.error(error);
            this.reconnect(subscriber, eventNames);
          },
        });
      }
    }, this.reconnectInterval);
  }
}
